<script setup>
const props = defineProps(['data']);

const defaultImage = '/img/dummy/ngp.png';

const { t } = useI18n();

const getImage = (item) => {
  return item.image_preview || defaultImage;
};
</script>

<template>
  <div class="container-max-content">
    <div class="file-grid">
      <div class="b-flex">
        <div v-for="(item, key) in props.data" :key="key" cols="12" md="6" class="b-flex-child">
          <div class="file-grid__item">
            <a :href="item.file.url" target="_blank" class="file-grid__image">
              <img :src="getImage(item)" :alt="item.name" class="file-grid__image-element" />
            </a>
            <div class="file-grid__main">
              <div class="file-grid__title">
                <a :href="item.file.url" target="_blank" class="file-grid__title-text">
                  {{ item.name }}
                </a>
              </div>
              <p class="file-grid__subtitle">{{ item.subtitle }}</p>
              <div class="file-grid__link">
                <a :href="item.file.url" target="_blank" class="button button--small button--transparent">{{ t('global.download') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.file-grid {
  margin-bottom: -20px;

  &__item {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    min-height: 128px;
  }

  &__image {
    flex-shrink: 0;
    height: 128px;
    width: 128px;
  }

  &__image-element {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__main {
    flex: 1;
    max-width: 300px;
    padding-left: 32px;
  }

  &__title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: -0.13px;
    line-height: 1.375;
  }

  &__subtitle {
    margin-top: 10px;
  }

  &__title-text {
    @include underline-transition(1px);
  }

  &__link {
    margin-top: 18px;
  }
}
</style>
